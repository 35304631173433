export const CHART_COLORS: string[] = [
  '#FFFFCC',
  '#DAF7A6',
  '#7FCDBB',
  '#87CEEB',
  '#FFBBFF', // Orchid
  '#FFA500', // Orange
  '#FFA07A',
  '#F08080',
  '#A0522D', // Sienna
  '#FF6347', // Tomato
  '#FFC300',
  '#FF8C00', // Dark Orange
  '#FFC0CB',
  '#FF69B4',
  '#E6E6FA', // Lavender
  '#F5DEB3', // Wheat
  '#FFF0F5', // Lavender Blush
  '#FF4500', // Orange Red
  '#6495ED', // Cornflower Blue
  '#9370DB', // Medium Purple
]
